import jawbtechlogo from "./_images/jawbtechlogo.png";
import Logo2 from "./_images/Logo2.png";

import "./App.css";

function App() {
  return (
    <div className="App">
      <img
        alt="Kurt's Heating and Cooling"
        src={Logo2}
        style={{ width: "600px", margin: "auto" }}
      />
      <h1>
        <b>Cell</b>: (519) 919-2618
      </h1>
      <h1>
        <b>Email</b>:{" "}
        <a href="mailto:kurt@kurtsheatingandcooling.com">
          Kurt@KurtsHeatingAndCooling.com
        </a>
      </h1>
      <div>
        &copy;{new Date().getFullYear()} All rights reserved and protected.
      </div>
      <a href="http://jawbtech.com" target="_blank" rel="noreferrer">
        <img
          alt="JAWBTech"
          src={jawbtechlogo}
          border="0"
          style={{ height: "30px" }}
        />
      </a>
    </div>
  );
}

export default App;
